import React from 'react'
import { Link as MuiLink } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    display: 'block'
  }
}))

const Link = ({
  className,
  children,
  inline,
  color = 'inherit',
  underline = 'none',
  ...rest
}) => {
  const classes = useStyles()
  return (
    <MuiLink {...rest} underline={underline} className={[classes.root, className]} color={color} style={inline ? { display: 'contents' } : { display: 'block' }}>
      {children}
    </MuiLink>
  )
}

export default Link

Link.propTypes = {
}
