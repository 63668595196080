export const round_one_criteria = [
  'innovation_description',
  'responsible_description',
  'impact_description'
]
export const round_two_criteria = [
  'innovation_description_round_2',
  'responsible_description_round_2',
  'impact_description_round_2'
]
export const round_two_future = [
  'scalability',
  'ecosystem_improvs',
  'obstacles'
]
