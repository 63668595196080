/*
if (typeof window === "undefined") {
    global.window = {};
}
*/

export const getScreenWidth = () => {
  return window.innerWidth
}

export const getScreenHeight = () => {
  return window.innerHeight
}

export const getImageOriginalHeight = (img) => {
  const imgOriginal = new Image()
  imgOriginal.src = img
  return imgOriginal.height
}

export const getImageOriginalWidth = (img) => {
  const imgOriginal = new Image()
  imgOriginal.src = img
  return imgOriginal.width
}

export const getImageOriginalProportion = (img) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => resolve(() => {
      const width = image.naturalWidth || image.width
      const height = image.naturalHeight || image.height
      return (
        width / height
      )
    })
    image.onerror = reject
    image.src = img
  })
}
