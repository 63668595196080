import { arrayUnion, collection, doc, getDoc, getDocs, writeBatch } from 'firebase/firestore'
import { firestoreDb } from '../config/firebase'
import { convertToCSV } from '../utils/string/stringUtils'
import _ from 'lodash'
import { getEntrants } from './mainController'
import { getCategories } from '../pages/dashboard/utility'

const groups = {
  'Sensors and Payload': 'Technology',
  'Software - Captured data management and analysis': 'Technology',
  'Software - Enabling flight and operational management': 'Technology',
  'Airspace Management': 'Operations',
  Delivery: 'Operations',
  'Survey and Inspection': 'Operations',
  Consultancy: 'Supporting services',
  'Training and Certification': 'Supporting services',
  'Construction and Planning': 'Industry / Field',
  'Defence Security and Surveillance': 'Industry / Field',
  Energy: 'Industry / Field',
  'Media Events and Creative': 'Industry / Field',
  'Medical and Healthcare': 'Industry / Field',
  'Conservation and Environment': 'Giving Back',
  'Education and Research': 'Giving Back',
  'Emergency Response and SAR': 'Giving Back',
  'Humanitarian Aid and Disaster Relief': 'Giving Back',
  'Public Safety': 'Giving Back'
}
export const importData = async (data, isPublic, callback) => {
  try {
    if (isPublic) {
      const companies = []
      const entries = data[0]
      for (let i = 1; i < data.length; i++) {
        companies[i - 1] = {}
        for (let j = 0; j < data[i].length; j++) {
          companies[i - 1][entries[j]] = data[i][j]
        }
      }
      await updatePublicCompanies(companies)
    } else {
      switch (data[0][0]) {
        case 'id': {
          if (data[0][1] === 'first_name') {
            const companies = []
            const entries = data[0]
            for (let i = 1; i < data.length; i++) {
              companies[i - 1] = {}
              for (let j = 0; j < data[i].length; j++) {
                // if (j >= 15 && entries[j] !== 'category') {
                //    if (!companies[i - 1]['answers']) {
                //       companies[i - 1]['answers'] = {}
                //    }
                //    companies[i - 1]['answers'][entries[j]] = data[i][j]
                // } else {
                companies[i - 1][entries[j]] = data[i][j]
                // }
              }
            }
            await addCompanies(companies, 'round_1')
          } else {
            const companies = []
            const entries = data[0]
            for (let i = 1; i < data.length; i++) {
              companies[i - 1] = {}
              for (let j = 0; j < data[i].length; j++) {
                companies[i - 1][entries[j]] = data[i][j]
              }
            }
            await updateCompanies(companies, 'round_2')
          }

          break
        }
        case 'email': {
          const judges = []
          const entries = data[0]
          for (let i = 1; i < data.length; i++) {
            judges[i - 1] = {}
            for (let j = 0; j < data[i].length; j++) {
              if (entries[j] === 'companies_ids') {
                judges[i - 1][entries[j]] = data[i][j].split(',')
              } else {
                judges[i - 1][entries[j]] = data[i][j]
              }
            }
          }
          await addJudges(judges)
          break
        }
      }
    }

    callback(true)
  } catch (e) {
    callback(false)
  }
}

const addCompanies = (companies, round) => {
  const batch = writeBatch(firestoreDb)

  for (const company of companies) {
    const answers = {
      [round]: {
        innovation_description: company.innovation_description,
        responsible_description: company.responsible_description,
        impact_description: company.impact_description
      }
    }
    const images = [
      company.additional_image_1,
      company.additional_image_2
    ]
    const entry_info = {
      entry_title: company.entry_title,
      entry_description: company.entry_description,
      cover_image: company.cover_image,
      use_case_country: company.use_case_country,
      is_under_embargo: company.is_under_embargo === 'TRUE',
      embargo_end_date: company.embargo_end_date,
      launch_date: company.launch_date
    }

    delete company.innovation_description
    delete company.responsible_description
    delete company.impact_description
    delete company.entry_title
    delete company.entry_description
    delete company.cover_image
    delete company.additional_image_1
    delete company.additional_image_2
    delete company.use_case_country
    delete company.is_under_embargo
    delete company.embargo_end_date
    delete company.launch_date
    const company_info = {
      ...company
    }
    const ref = doc(firestoreDb, 'entrants', company.id)
    batch.set(ref, { company_info, answers, images, entry_info, public_voting: true }, { merge: true })
    // const roundAnswers = doc(firestoreDb, "entrants", company.id, "round_1", "answers");
    // batch.set(roundAnswers, answers);
  }

  return batch.commit()
}

const updateCompanies = (companies, round) => {
  const batch = writeBatch(firestoreDb)

  for (const company of companies) {
    const id = company.id
    const images = [
      company.additional_image_3,
      company.additional_image_4,
      company.additional_image_5
    ]

    const entry_info = {
      video_url: company.video_url,
      consortia_team_involved: company.consortia_team_involved,
      technologies_used: company.technologies_used
    }

    const answers = {
      [round]: {
        innovation_description_round_2: company.innovation_description,
        responsible_description_round_2: company.responsible_description,
        impact_description_round_2: company.impact_description,
        scalability: company.scalability,
        ecosystem_improvs: company.ecosystem_improvs,
        obstacles: company.obstacles
      }
    }

    const ref = doc(firestoreDb, 'entrants', id/* "round_2", "answers" */)
    batch.set(ref, { images: arrayUnion(...images), entry_info, answers }, { merge: true })
  }

  return batch.commit()
}
const updatePublicCompanies = (companies) => {
  const batch = writeBatch(firestoreDb)

  for (const company of companies) {
    const id = company.id

    const company_info = {
      id,
      group: company.group,
      category: company.category,
      cover_image: company.cover_image,
      first_name: company.first_name,
      last_name: company.last_name,
      company_name: company.company_name,
      social_media_urls: company.social_media_urls,
      website_url: company.website_url,
      reason: company.reason,
      statement: company.statement
    }
    const ref = doc(firestoreDb, 'public_entrants', id/* "round_2", "answers" */)
    batch.set(ref, {
      company_info
    }, { merge: true })
  }

  return batch.commit()
}

const addJudges = async (judges) => {
  console.log('judges', judges)
  let round

  for (const judge of judges) {
    const batch = writeBatch(firestoreDb)
    round = judge.round
    const ref = doc(firestoreDb, 'judges', judge.email.toLowerCase())
    const roundJudges = 'round_' + judge.round + '_judges'
    batch.set(ref, {
      password: judge.password
    }, { merge: true })
    for (const companyId of judge.companies_ids) {
      const companyRef = doc(firestoreDb, 'entrants', companyId.trim())
      batch.update(companyRef, { [roundJudges]: arrayUnion(judge.email.toLowerCase()) })
      // const round = doc(firestoreDb, "judges", judge.email, "round_" + judge.round, companyId);
      // batch.set(round, {votes: {}});
    }
    await batch.commit()
  }
  /* const ref = doc(firestoreDb, "info", 'current');
    batch.set(ref, {current_round: 'round_' + round}, {merge: true}) */

  return true
}

export const exportVotes = async () => {
  const currentRoundRef = doc(firestoreDb, 'info', 'current')
  const docSnap = await getDoc(currentRoundRef)
  const arrayOfVotes = []
  const currentRound = docSnap.data().current_round
  const docsSnap = await getDocs(collection(firestoreDb, 'votes', currentRound, 'entrants'))
  docsSnap.forEach((doc) => {
    const companyId = doc.id
    const votes = doc.data().votes
    const feedback = doc.data().feedback
    const category = doc.data().category
    for (const judge of _.keys(votes)) {
      if (currentRound === 'round_3') {
        arrayOfVotes.push({
          id: companyId,
          judge,
          category,
          rank: votes[judge]
        })
      } else if (currentRound === 'round_2') {
        if (category) {
          arrayOfVotes.push({
            id: companyId,
            category,
            group: groups[category],
            judge,
            question_1: votes[judge][0],
            question_2: votes[judge][1],
            question_3: votes[judge][2],
            what_liked: feedback[judge][0],
            can_improved: feedback[judge][1],
            can_changed: feedback[judge][2]
          })
        }
      } else {
        arrayOfVotes.push({
          id: companyId,
          judge,
          question_1: votes[judge][0],
          question_2: votes[judge][1],
          question_3: votes[judge][2]
        })
      }
    }
  })

  console.log(arrayOfVotes)
  const csv = convertToCSV(arrayOfVotes)
  const element = document.createElement('a')
  const file = new Blob([csv], { type: 'text/csv' })
  element.href = URL.createObjectURL(file)
  element.download = 'votes_' + currentRound + '.csv'
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}
export const exportProvisionalVotes = async () => {
  const currentRoundRef = doc(firestoreDb, 'info', 'current')
  const docSnap = await getDoc(currentRoundRef)
  const arrayOfVotes = []
  const currentRound = docSnap.data().current_round
  const docsSnap = await getDocs(collection(firestoreDb, 'judges'))
  for (const doc of docsSnap.docs) {
    const judge = doc.id
    const completedVotes = doc.data().completed_votes
    const roundVotesPath = currentRound + '_votes'
    const votes = doc.data()[roundVotesPath]
    if (completedVotes?.includes(currentRound)) {
      if (currentRound === 'round_3') {
        arrayOfVotes.push({
          judge,
          criteria_voted: _.keys(votes).length,
          total_criteria: _.keys(votes).length
        })
      } else {
        arrayOfVotes.push({
          judge,
          criteria_voted: _.keys(votes).length * 3,
          total_criteria: _.keys(votes).length * 3
        })
      }
    } else {
      const entrants = await getEntrants(judge, currentRound)
      if (currentRound === 'round_3') {
        const categories = getCategories(entrants)
        arrayOfVotes.push({
          judge,
          criteria_voted: _.keys(votes).length,
          total_criteria: categories.length
        })
      } else {
        arrayOfVotes.push({
          judge,
          criteria_voted: _.keys(votes).length * 3,
          total_criteria: entrants.length * 3
        })
      }
    }
  }
  /* docsSnap.forEach(  (doc) => {

   }); */

  const csv = convertToCSV(arrayOfVotes)
  const element = document.createElement('a')
  const file = new Blob([csv], { type: 'text/csv' })
  element.href = URL.createObjectURL(file)
  element.download = 'provisional_votes_' + currentRound + '.csv'
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

export const exportPublicVoting = async () => {
  const arrayOfVotes = []
  const docsSnap = await getDocs(collection(firestoreDb, 'public_voting'))
  for (const doc of docsSnap.docs) {
    const companyId = doc.id
    const votes = doc.data().votes
    arrayOfVotes.push({
      companyId,
      votes: votes.length
    })
  }

  const csv = convertToCSV(arrayOfVotes, true)
  const element = document.createElement('a')
  const file = new Blob([csv], { type: 'text/csv' })
  element.href = URL.createObjectURL(file)
  element.download = 'public_votes.csv'
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

export const exportPublicVotingUser = async () => {
  let arrayOfVotes = []
  const companies = {}
  const docsSnap = await getDocs(collection(firestoreDb, 'public_voting_users'))
  console.log(docsSnap.docs)
  for (const doc of docsSnap.docs) {
    const votes = doc.data().votes
    console.log(votes)
    for (const voteKey of Object.keys(votes)) {
      if (!companies[votes[voteKey]]) {
        companies[votes[voteKey]] = {
          companyId: votes[voteKey],
          votes: 1
        }
      } else {
        companies[votes[voteKey]] = {
          companyId: votes[voteKey],
          votes: companies[votes[voteKey]].votes + 1
        }
      }
    }
  }
  arrayOfVotes = Object.values(companies)
  const csv = convertToCSV(arrayOfVotes, true)
  const element = document.createElement('a')
  const file = new Blob([csv], { type: 'text/csv' })
  element.href = URL.createObjectURL(file)
  element.download = 'public_votes.csv'
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}
