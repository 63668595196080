import { checkSignin } from '../controllers/signinController'
import { createContext, useContext, useState } from 'react'

const AuthContext = createContext(null)

export function AuthProvider ({ children }) {
  const [user, setUser] = useState(localStorage.getItem('userLogin'))

  const signin = async (email, password) => {
    const user = await checkSignin(email, password)
    localStorage.setItem('userLogin', user)
    setUser(user)
  }

  const signout = () => {
    localStorage.setItem('userLogin', null)
    setUser(null)
  }

  const value = { user, signin, signout }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export function useAuth () {
  return useContext(AuthContext)
}
