import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import S from '../../res/styles'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import R from '../../res/variables'
import { darkenColor, lightenColor } from '../../utils/colors/Colors'

const defaultBackgroundColor = R.palette.backgroundColored1
const defaultBackgroundColor2 = R.palette.backgroundColored2

const View = ({
  className,
  children,
  flex = false,
  flex1 = false,
  flexGrow = false,
  flexShrink = false,
  centerCenter = false,
  overflowHidden = false,
  wrap = false,
  row = false,
  column = false,
  justify,
  alignItems,
  clickable,
  showBackgroundColor,
  backgroundColor,
  backgroundColor2,
  minHeight = false,
  gradient = false,
  fullWidth,
  padding = 0,
  hiddenUp = false,
  hiddenDown = false,
  pv = 0,
  ph = 0,
  hover = false,
  ...rest
}) => {
  const color1 = backgroundColor || defaultBackgroundColor
  const color2 = backgroundColor ? backgroundColor2 : defaultBackgroundColor2
  const classes = useStyles({
    flex,
    justify,
    alignItems,
    color1,
    color2,
    minHeight,
    padding,
    hiddenUp,
    hiddenDown,
    pv,
    ph
  })

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.flex]: flex,
      [classes.flex1]: flex1,
      [classes.flexGrow]: flexGrow,
      [classes.flexShrink]: flexShrink,
      [classes.overflowHidden]: overflowHidden,
      [classes.wrap]: wrap,
      [classes.row]: row,
      [classes.column]: column,
      [classes.centerCenter]: centerCenter,
      [classes.justify]: justify,
      [classes.alignItems]: alignItems,
      [classes.clickable]: clickable,
      [classes.clickableHighlight]: clickable === 'highlight',
      [classes.minHeight]: minHeight,
      [classes.backgroundColor]: showBackgroundColor && !gradient,
      [classes.backgroundColorGradient]: gradient,
      [classes.fullWidth]: fullWidth,
      [classes.padding]: padding,
      [classes.hiddenUp]: hiddenUp,
      [classes.hiddenDown]: hiddenDown,
      [classes.pv]: pv > 0,
      [classes.ph]: ph > 0
    },
    className
  )

  return (
    <Box
      {...rest}
      className={rootClassName}
    >
      {children}
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  root: {},
  flex: props => ({
    ...S.layout.flex,
    flex: props.flex || 'initial'
  }),
  flex1: { ...S.layout.flex1 },
  flexGrow: { ...S.layout.flexGrow },
  flexShrink: { ...S.layout.flexShrink },
  overflowHidden: { ...S.layout.overflowHidden },
  wrap: { ...S.layout.wrap },
  row: { ...S.layout.row },
  column: { ...S.layout.column },
  centerCenter: { ...S.layout.centerCenter },
  justify: props => ({ display: 'flex', justifyContent: props.justify }),
  alignItems: props => ({ display: 'flex', alignItems: props.alignItems }),
  minHeight: props => ({ minHeight: props.minHeight }),
  clickable: {
    '&:hover': {
      cursor: 'pointer'
    },
    '& .MuiTypography-root:hover': {
      cursor: 'pointer'
    }
  },
  clickableHighlight: {
    '&:hover': {
      backgroundColor: R.palette.themeDark ? lightenColor(R.palette.backgroundColorDark, 0.2) : darkenColor(R.palette.backgroundColorLight, 0.2)
    }
  },
  backgroundColor: props => ({
    backgroundColor: props.color1,
    ...S.layout.flex1
  }),
  backgroundColorGradient: props => ({
    background: 'linear-gradient(to bottom right, ' + props.color1 + ', ' + props.color2 + ')',
    ...S.layout.flex1,
    ...S.layout.flex
  }),
  padding: props => ({
    padding: theme.spacing(props.padding)
  }),
  fullWidth: {
    width: '100%'
  },
  hiddenUp: props => ({
    [theme.breakpoints.up(props.hiddenUp)]: {
      display: 'none'
    }
  }),
  hiddenDown: props => ({
    [theme.breakpoints.down(props.hiddenDown)]: {
      display: 'none'
    }
  }),
  pv: props => ({
    paddingTop: theme.spacing(props.pv),
    paddingBottom: theme.spacing(props.pv)
  }),
  ph: props => ({
    paddingLeft: theme.spacing(props.ph),
    paddingRight: theme.spacing(props.ph)
  })

}), { name: 'Mui-view' })

View.propTypes = {
  children: PropTypes.node,
  clickable: PropTypes.bool,
  flex: PropTypes.oneOfType([PropTypes.bool || PropTypes.number]),
  flex1: PropTypes.bool,
  flexGrow: PropTypes.bool,
  flexShrink: PropTypes.bool,
  overflowHidden: PropTypes.bool,
  wrap: PropTypes.bool,
  row: PropTypes.bool,
  column: PropTypes.bool,
  centerCenter: PropTypes.bool,
  minHeight: PropTypes.bool,
  justify: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'space-between', 'space-around', 'space-evenly', 'baseline']),
  alignItems: PropTypes.oneOf([false, 'flex-start', 'center', 'flex-end', 'stretch', 'baseline']),
  backgroundColor: PropTypes.string,
  backgroundColor2: PropTypes.string,
  showBackgroundColor: PropTypes.bool,
  gradient: PropTypes.bool
}

export default View
