import React, {useState, useEffect} from 'react';
import View from '../theme/View';
import {makeStyles} from '@mui/styles';
import Typography from '../theme/Typography';
import Spacer from '../Spacer/Spacer';
import {useViewport} from '../../utils/dimensions/Viewport';
import VoteBox from '../VoteBox/VoteBox';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import _ from 'lodash';
import Grid from '../theme/Grid';

let rank = {};

const CompaniesList = ({
  companiesList,
  questsList,
  questsDescription,
  onVoteChange,
  onFeedbackChange,
  initialVotes,
  initialFeedback,
  category,
  onBackButtonClicked,
  round,
  submitError,
  isPublic = false,
  onVoteClick,
  onRankChange,
  numRank,
}) => {
  const classes = useStyles();
  const {isXS} = useViewport();
  const [companyIdsSelected, setCompanyIdsSelected] = useState([]);

  useEffect(() => {
    for (let i = 1; i < numRank + 1; i++) {
      rank = {
        ...rank,
        [i]: null,
      };
    }
    setCompanyIdsSelected([]);
  }, [companiesList]);

  const handleOnVoteClick = (item, index) => {
    const cloneIds = [...companyIdsSelected];

    const prevValue = rank[1];
    if (!item) {
      const idRemoved = rank[1];
      cloneIds.splice(cloneIds.indexOf(idRemoved), 1);
      rank[1] = null;
    } else if (prevValue) {
      cloneIds.splice(cloneIds.indexOf(prevValue), 1);
      rank[1] = null;
      cloneIds.push(item.company_info.id);
      rank[1] = item.company_info.id;
    } else {
      cloneIds.push(item.company_info.id);
      rank[1] = item.company_info.id;
    }

    setCompanyIdsSelected(cloneIds);
    onRankChange(rank);
    onVoteClick();
  };

  return (
    <View className={classes.root} centerCenter flex column>
      {onBackButtonClicked && (
        <View flex justify="flex-start" fullWidth>
          <IconButton onClick={onBackButtonClicked}>
            <ArrowBackIcon />
          </IconButton>
        </View>
      )}
      <View className={classes.wrapper}>
        <Typography align="center" variant={isXS ? 'h3' : 'h4'} component="h2">
          {category}
        </Typography>
        <Spacer size={2} />
        <Grid container lg={isPublic ? 12 : 12} flex justifyContent="center">
          {companiesList &&
            companiesList.map((item, index) => (
              <Grid
                item
                xs={isPublic ? 6 : 12}
                lg={isPublic ? 2.3 : 12}
                md={isPublic ? 2.3 : 12}
                key={item.company_info.id}
              >
                <VoteBox
                  item={item}
                  index={index}
                  questsList={questsList}
                  questsDescription={questsDescription}
                  initialVotes={initialVotes?.[item.company_info.id]}
                  initialFeedback={initialFeedback?.[item.company_info.id]}
                  onVoteChange={onVoteChange}
                  onFeedbackChange={(value, index) => {
                    onFeedbackChange(item.company_info.id, index, value);
                  }}
                  onVoteClick={handleOnVoteClick}
                  round={round}
                  submitError={submitError}
                  isPublic={isPublic}
                  submitted={
                    initialVotes?.[item.company_info.id] &&
                    _.every(
                      initialVotes?.[item.company_info.id] || [],
                      (item) => item !== 0
                    ) &&
                    _.every(
                      initialFeedback?.[item.company_info.id] || [],
                      (item) => item !== ''
                    )
                  }
                />
              </Grid>
            ))}
        </Grid>
      </View>
      {!_.isEmpty(submitError) && (
        <Typography
          align="center"
          variant="h8"
          component="h8"
          style={{color: 'red'}}
        >
          Please complete the entrants you already started judging
        </Typography>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  wrapper: {
    width: '100%',
  },
}));

export default CompaniesList;
