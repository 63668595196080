const themeDark = 0
const palette = {
  themeDark,

  primary: '#3E6ED8',
  secondary: '#C71585',

  // BACKGROUND DARK
  backgroundColorDark: '#121212',
  backgroundPaperDark: '#1d1d1d',
  backgroundSidebarDark: '#000',
  // BACKGROUND LIGHT
  backgroundColorLight: '#fff',
  backgroundPaperLight: '#fafafa',

  // BACKGROUND COLORED
  // backgroundColored2:'#5f62ff',
  // backgroundColored1:'#9cc0ff',191970
  backgroundColored2: '#68B163',
  backgroundColored1: '#2C8E6B',

  // BUTTONS
  info: '#2196f3',
  warning: '#ff9800',
  success: '#4caf50',
  error: '#f44336',
  grey: '#f5f5f5',

  // TYPOGRAPHY
  textPrimaryLight: 'rgba(0,0,0,0.87)',
  textSecondaryLight: '#757575',
  textPrimaryDark: '#fff',
  textSecondaryDark: 'rgba(255,255,255,0.7)',
  textLight: '#c8c8d2',
  // BrandLightText: "#c8c8d2",

  // MATERIAL COLORS
  black: '#000',
  white: '#FFF',
  lightBlue: '#62b1f6',
  greySet: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161'
  },
  pink: '#f50057',

  // BRANDS COLORS
  facebook: '#1877f2',
  apple: themeDark ? '#ffffff' : '#000000',
  google: '#ea4335'
}
export default palette
