import React from 'react'
import { makeStyles } from '@mui/styles'
import RankField from '../Company/RankField'
import Spacer from '../Spacer/Spacer'
import View from '../theme/View'

const RankBox = ({ companiesList, numRank, companyIdsSelected, onOptionChange, isPublic }) => {
  const classes = useStyles()

  function renderItem (index) {
    return (
      <View>
        <RankField
          companiesList={companiesList}
          companyIdsSelected={companyIdsSelected}
          index={index}
          onOptionChange={onOptionChange}
          isPublic={isPublic}
        />
        <Spacer size={0.5} />
      </View>
    )
  }

  return (
    <View>
      {Array.from({ length: numRank - 1 + 1 }, (_, i) => renderItem(i + 1))}
    </View>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'

  }
}))

export default RankBox
