import React from 'react'
import HeaderPage from './HeaderPage'
import HeaderArticle from './HeaderArticle'

export const pageContext = React.createContext({ pageType: '' })

const PageContainer = ({
  children,
  trackPageType,
  pageInfo,

  // HEADER
  header = false,
  headerSubTitle = false,
  headerSubTitle2 = false,
  article = false
}) => {
  return (
    <pageContext.Provider value={{ pageType: trackPageType }}>
      {article
        ? <HeaderArticle
            title={pageInfo?.title}
            img={pageInfo?.headerImage}
            subTitle={headerSubTitle}
            subTitle2={headerSubTitle2}
            meta={pageInfo?.meta}
          >
          {children}
        </HeaderArticle>
        : header
          ? <HeaderPage
              title={pageInfo?.title}
              img={pageInfo?.headerImage}
              subTitle={headerSubTitle}
              subTitle2={headerSubTitle2}
            >
            {children}
          </HeaderPage>
          : children}
    </pageContext.Provider>
  )
}

export default PageContainer
