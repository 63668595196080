import React, { useState } from 'react'
import CSVReader from 'react-csv-reader'
import './styles.css'
import Button from '@mui/material/Button'
import { Box, CircularProgress } from '@mui/material'
import {
  exportProvisionalVotes,
  exportPublicVoting,
  exportPublicVotingUser,
  exportVotes,
  importData
} from '../../controllers/importController'

let csvData = null
let fileUri = null
const Import = () => {
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const handleSubmit = async (event) => {
    event.preventDefault()
    if (fileUri) {
      importData(csvData, false, (success) => {
        setLoading(false)
        if (success) {
          setStatus('Success!')
        } else {
          setStatus('Error!')
        }
        console.log('finished')
      })
      console.log('started')
      setLoading(true)
    }
  }
  const handlePublicSubmit = async (event) => {
    event.preventDefault()
    if (fileUri) {
      importData(csvData, true, (success) => {
        setLoading(false)
        if (success) {
          setStatus('Success!')
        } else {
          setStatus('Error!')
        }
        console.log('finished')
      })
      console.log('started')
      setLoading(true)
    }
  }

  const handleDownloadRound = async (event) => {
    event.preventDefault()
    exportVotes()
  }
  const handleDownloadProvisionalVotes = async (event) => {
    event.preventDefault()
    exportProvisionalVotes()
  }
  const handleDownloadPublicVotes = async (event) => {
    event.preventDefault()
    exportPublicVotingUser()
  }

  return (
    <div className='container'>
      <div>
        <CSVReader
          cssClass='react-csv-input'
          onFileLoaded={(data, fileInfo, originalFile) => {
            setStatus(null)
            csvData = data
            fileUri = originalFile
          }}
          label='Select CSV you want to import'
        />
      </div>
      <div>
        {
               loading
                 ? <CircularProgress />
                 : <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                   <Button
                     type='submit'
                     variant='contained'
                     sx={{ mt: 1, mb: 2 }}
                   >
                     Import
                   </Button>
                   <h3>{status}</h3>
                 </Box>
            }
      </div>
      <div>
        <CSVReader
          cssClass='react-csv-input'
          onFileLoaded={(data, fileInfo, originalFile) => {
            setStatus(null)
            csvData = data
            fileUri = originalFile
          }}
          label='Select CSV For the Public Voting'
        />
      </div>
      <div>
        {
               loading
                 ? <CircularProgress />
                 : <Box component='form' onSubmit={handlePublicSubmit} noValidate sx={{ mt: 1 }}>
                   <Button
                     type='submit'
                     variant='contained'
                     sx={{ mt: 1, mb: 2 }}
                   >
                     Import
                   </Button>
                   <h3>{status}</h3>
                 </Box>
            }
      </div>
      <div>
        <Box component='form' onSubmit={handleDownloadRound} noValidate sx={{ mt: 1 }}>
          <Button
            type='submit'
            variant='contained'
            sx={{ mt: 1, mb: 2 }}
          >
            Download Votes For Current Round
          </Button>
        </Box>
        <Box component='form' onSubmit={handleDownloadProvisionalVotes} noValidate sx={{ mt: 1 }}>
          <Button
            type='submit'
            variant='contained'
            sx={{ mt: 1, mb: 2 }}
          >
            Download Provisional Votes
          </Button>
        </Box>
        <Box component='form' onSubmit={handleDownloadPublicVotes} noValidate sx={{ mt: 1 }}>
          <Button
            type='submit'
            variant='contained'
            sx={{ mt: 1, mb: 2 }}
          >
            Download Public Votes
          </Button>
        </Box>
      </div>
    </div>
  )
}

export default Import
