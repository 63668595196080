import View from '../../../components/theme/View'
import { makeStyles } from '@mui/styles'
import React from 'react'
import Spacer from '../../../components/Spacer/Spacer'
import ToolbarSpacer from '../../../components/Spacer/ToolbarSpacer'
import { useViewport } from '../../../utils/dimensions/Viewport'
import Container from '../../../components/theme/Container'

const HeaderPage = ({ children, title, img, subTitle, subTitle2, meta }) => {
  const classes = useStyles()
  const { isXS } = useViewport()

  return (
    <View className={classes.root}>
      <View
        className={[classes.mainWrapper]}
        centerCenter
        column
        role='img'
        aria-label={img.alt}
        style={{
          backgroundImage: `url(${img.src})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          width: '100%'
        }}
      >
        <View className={classes.opacity}>
          <ToolbarSpacer />
          <Container maxWidth='md'>
            {/* <Typography
                        variant={isXS ? 'h3' : 'h2'}
                        component={'h1'}
                        oppositeColor
                        className={classes.paddingWrapper}
                        align={'center'}
                    >{title}</Typography> */}
          </Container>
        </View>
      </View>
      <View centerCenter row>
        <View column centerCenter>
          {children}
        </View>
      </View>
      <Spacer size={3} />
    </View>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  opacity: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    minHeight: 250
  },
  mainWrapper: {},
  paddingWrapper: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  },
  body: {}
}))

export default HeaderPage
