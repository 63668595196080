import View from './theme/View';
import {ImageList, ImageListItem} from '@mui/material';
import Typography from './theme/Typography';
import React from 'react';

const YourVotes = ({votes}) => {
  return (
    <View flex alignItems="center" column>
      <Typography align="center" variant="h5" component="h2">
        Your votes
      </Typography>
      <ImageList
        sx={{width: 500, height: 230 * Math.ceil(votes.length / 3)}}
        gap={0}
        cols={3}
        variant="quilted"
        rowHeight={100}
      >
        {votes.map((item) => (
          <>
            {item ? (
              <ImageListItem
                key={item.company_info.id}
                style={{margin: '5px', height: '150px'}}
              >
                <img
                  src={`${item.company_info.cover_image}`}
                  alt="company logo"
                  loading="lazy"
                />
                <div style={{padding: '5px 0', textAlign: 'center'}}>
                  {item.company_info.group === 'Organisation'
                    ? item.company_info.company_name
                    : `${item.company_info.first_name} ${item.company_info.last_name}`}
                </div>
              </ImageListItem>
            ) : null}
          </>
        ))}
      </ImageList>
    </View>
  );
};

export default YourVotes;
