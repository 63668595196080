import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import S from '../../res/styles'
import { List as MuiList } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  root: {
  },
  flex: {
    ...S.layout.flex
  },
  horizontal: {
    ...S.layout.horizontal
  }
}))

const List = ({ className, children, flex, horizontal, ...rest }) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.flex]: flex,
      [classes.horizontal]: horizontal
    },
    className
  )

  return (
    <MuiList
      {...rest}
      className={rootClassName}
    >
      {children}
    </MuiList>
  )
}

List.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  flex: PropTypes.bool,
  horizontal: PropTypes.bool
}

List.defaultProps = {
  flex: false,
  horizontal: false
}

export default List
