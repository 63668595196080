import React from 'react'
import { makeStyles } from '@mui/styles'
import CustomRadioGroup from './CustomRadioGroup'
import View from '../theme/View'
import Typography from '../theme/Typography'

const QuestionBox = ({ item, index, onVoteChanged, initialVote, round, error }) => {
  const classes = useStyles()
  const isXS = window.innerWidth < 600

  return (
    <View row={!isXS} column={isXS} flex alignItems='center' justify='space-between'>
      <View>
        {index === 0 &&
          <View style={{ width: 10, height: 20 }} />}
        <Typography variant='body1'>{item}</Typography>
      </View>
      <CustomRadioGroup hideLabel={index !== 0} initialVote={initialVote} onVoteChanged={onVoteChanged} max={round === 'round_1' ? 5 : 10} error={error} />
    </View>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'

  }
}))

export default QuestionBox
