import React from 'react'
import View from '../../../components/theme/View'
import { Typography, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useViewport } from '../../../utils/dimensions/Viewport'
import S from '../../../res/styles'

const MenuFooter = () => {
  const { isXS } = useViewport()
  const classes = useStyles()

  const onClick = () => {
  }
  /* useEffect(() => () => {
    }) */

  return (
    <View row={!isXS} centerCenter flex={!isXS}>
      <View className={classes.linkWrapper}>
        <Link
          href='https://www.iubenda.com/privacy-policy/29909696/full-legal'
          passHref
          color='inherit'
          target='_blank' rel='noreferrer'
        >
          <Typography>Privacy Policy</Typography>
        </Link>
      </View>
      <View className={classes.linkWrapper}>
        <Link href='/contact-us' passHref color='inherit'>
          <Typography>Contact Us</Typography>
        </Link>
      </View>
      <View className={classes.linkWrapper}>
        <Link
          href='https://www.iubenda.com/terms-and-conditions/29909696'
          passHref
          color='inherit'
          target='_blank' rel='noreferrer'
        >
          <Typography>Terms & Conditions</Typography>
        </Link>
      </View>
      <View className={classes.linkWrapper}>
        <Link
          href='/blog'
          passHref
          color='inherit'
        >
          <Typography>How To</Typography>
        </Link>
      </View>
    </View>
  )
}

const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    ...S.layout.centerCenter,
    ...S.layout.flex
  }
}))

export default MenuFooter
