import React from 'react'
import { Autocomplete, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'
import View from '../theme/View'
import Spacer from '../Spacer/Spacer'
import FormControl from '@mui/material/FormControl'
import { useViewport } from '../../utils/dimensions/Viewport'

const RankField = ({ item, index, companiesList, companyIdsSelected, onOptionChange, isPublic }) => {
  const { isXS } = useViewport()
  const companies = companiesList.filter(company => !companyIdsSelected.includes(company.company_info.id))

  console.log('companyIdsSelected', companyIdsSelected)
  const selectedList = companiesList.filter(company => companyIdsSelected.includes(company?.company_info?.id))
  let selected
  if (selectedList.length > 0) {
    selected = selectedList.find(company => company?.company_info?.id === companyIdsSelected[index - 1])
  }
  return (
    <View row fullWidth flex centerCenter>
      {!isPublic && <p>{index}</p>}
      {!isPublic && <Spacer horizontal />}
      {/* <Autocomplete
            key={companiesList}
            id="asynchronous-demo"
            style={{width: 400}}
            getOptionDisabled={option => option.disabled}
            getOptionSelected={(option, value) => option.company_name === value.company_info.company_name}
            getOptionLabel={option => (option.company_info.group && option.company_info.group === 'Person' ? option.company_info.first_name + ' ' + option.company_info.last_name :option.company_info.company_name)}
            // getOptionValue={option => option.id}
            // options={companiesList}
            options={companiesList.filter(company => !companyIdsSelected.includes(company.company_info.id))}
            onChange={(event, value) => onOptionChange(event, value, index)}
            noOptionsText={''}
            renderInput={params => (
               <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  // onChange={(option, value) => console.log("222", option, value)}
                  InputProps={{
                     ...params.InputProps
                  }}
               />
            )}
         /> */}
      <FormControl size='small' variant='outlined' style={{ minWidth: isXS ? '80%' : 300 }}>
        <Select
          onChange={(event, value) => onOptionChange(event, index)}
          renderValue={params => (
            <>
              {selected?.company_info?.group === 'Person' ? selected?.company_info?.first_name + ' ' + selected?.company_info?.last_name : selected?.company_info?.company_name}
            </>
          )}
        >
          {companies.map((item) => (
            <MenuItem
              key={item}
              value={item}
            >
              {item.company_info.group === 'Person' ? item.company_info.first_name + ' ' + item.company_info.last_name : item.company_info.company_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </View>
  )
}

export default RankField
