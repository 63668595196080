import View from '../theme/View'
import React from 'react'
import { trackEvent } from '../../utils/eventsTracking/gtag'
import { events } from '../../utils/eventsTracking/events'
import { IconButton } from '@mui/material'
import { Audiotrack, Facebook, Instagram, Pinterest, Telegram, Twitter } from '@mui/icons-material'

const SocialMedia = ({ fullWidth }) => {
  const handleClick = (social) => {
    trackEvent(events.button_social_media_clicked, { platform: social })
  }
  return (
    <View row flex centerCenter>
      <label htmlFor='icon-button-file'>
        <IconButton
          onClick={() => handleClick('facebook')}
          color='inherit'
          aria-label='facebook social media'
          target='_blank'
          href='https://www.facebook.com/TextVibesapp'
        >
          <Facebook />
        </IconButton>
      </label>
      <label htmlFor='icon-button-file'>
        <IconButton
          onClick={() => handleClick('instagram')}
          color='inherit'
          aria-label='instagram social media'
          target='_blank'
          href='https://www.instagram.com/text__vibes/'
        >
          <Instagram />
        </IconButton>
      </label>
      <label htmlFor='icon-button-file'>
        <IconButton
          onClick={() => handleClick('twitter')}
          color='inherit'
          aria-label='twitter social media'
          target='_blank'
          href='https://twitter.com/Text__Vibes'
        >
          <Twitter />
        </IconButton>
      </label>
      <label htmlFor='icon-button-file'>
        <IconButton
          onClick={() => handleClick('tiktok')}
          color='inherit'
          aria-label='tiktok social media'
          target='_blank'
          href='https://vm.tiktok.com/ZMepndXe2/'
        >
          <Audiotrack />
        </IconButton>
      </label>
      <label htmlFor='icon-button-file'>
        <IconButton
          onClick={() => handleClick('pinterest')}
          color='inherit'
          aria-label='pinterest social media'
          target='_blank'
          href='https://pin.it/1v6QgRh'
        >
          <Pinterest />
        </IconButton>
      </label>
      <label htmlFor='icon-button-file'>
        <IconButton
          onClick={() => handleClick('telegram')}
          color='inherit'
          aria-label='telegram social media'
          target='_blank'
          href='https://t.me/joinchat/030ylc7dLGNjMDg0'
        >
          <Telegram />
        </IconButton>
      </label>
    </View>
  )
}

SocialMedia.propTypes = {}

export default SocialMedia
