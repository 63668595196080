import { darken, lighten } from '@mui/material'
import palette from './palette'

const beWise = {
  elementColor: palette.themeDark ? lighten(palette.backgroundColorDark, 0.05) : darken(palette.backgroundColorLight, 0.05),
  elementColorHover: palette.themeDark ? lighten(palette.backgroundColorDark, 0.1) : darken(palette.backgroundColorLight, 0.1),

  elementLinkImageSize: 75,
  // height of category items in sidebar
  listItemSidebarSize: 38,
  // height and width of header image
  headerImageSize: 120,
  // height of header
  headerHeight: 200
}
export default beWise
