import React from 'react'

// Externals
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Material helpers
// Material components
import SocialMedia from '../../../components/SocialMedia/SocialMedia'
import View from '../../../components/theme/View'
import Spacer from '../../../components/Spacer/Spacer'
import { makeStyles } from '@mui/styles'
import Typography from '../../../components/theme/Typography'
import R from '../../../res/variables'
import MenuFooter from '../Menu/MenuFooter'
import { useViewport } from '../../../utils/dimensions/Viewport'

// Component styles
const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8)
  },

  company: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  }
}))

const Footer = ({ className }) => {
  const { isXS } = useViewport()
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true
    },
    className
  )

  return (
    <View className={rootClassName}>
      <View centerCenter column>
        <SocialMedia />
        <Spacer size={3} />
        <MenuFooter />
        <Spacer size={3} />
        <View row={!isXS} column={isXS} flex centerCenter justify='baseline'>
          <Typography color={R.palette.greySet['600']} variant='subtitle2' weight={300} style={{ fontSize: '0.8rem' }}>Powered with ❤️ by</Typography>
          <Spacer horizontal size={0.5} hide={isXS} />
          <Typography color={R.palette.greySet['700']} variant='subtitle2' weight={700} style={{ fontSize: '0.8rem' }}>SweetieDev LTD</Typography>
        </View>
      </View>
    </View>
  )
}

Footer.propTypes = {
  className: PropTypes.string
}

export default Footer
