import * as React from 'react'
import { useEffect, useState } from 'react'
import { createTheme } from '@mui/material/styles'
import { useAuth } from '../../contexts/authContext'
import { useNavigate } from 'react-router-dom'
import MainLayout from '../../layouts/MainLayout/MainLayout'
import PageContainer from '../../layouts/components/Body/PageContainer'
import View from '../../components/theme/View'
import CategoriesGrid from '../../components/Categories/CategoriesGrid'
import {
  getCurrentVotes,
  getEntrants,
  getGeneralInfo,
  getQuestions,
  saveCompleteRound, saveCurrentFeedback,
  saveCurrentVotes, submitFeedback,
  submitVotes, workAround
} from '../../controllers/mainController'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { containsNull, getCategories } from './utility'
import CompaniesList from '../../components/Company/CompaniesList'
import CompaniesRank from '../../components/Company/CompaniesRank'
import Spacer from '../../components/Spacer/Spacer'
import Box from '@mui/material/Box'
import Button from '../../components/theme/Button'
import _ from 'lodash'
import ThanksPage from '../../components/ThanksPage/ThanksPage'
import Typography from '../../components/theme/Typography'

const theme = createTheme()
let votes = {}
let info = {}
let rank = {}
let currentVoted = {}
let filteredEntrants = []
let feedback = {}

export default function DashboardPage () {
  const [entrants, setEntrants] = useState(null)
  const [categories, setCategories] = useState(null)
  const [quests, setQuests] = useState(null)
  const [isContinueActive, setUnlockContinue] = useState(false)
  const [isRoundCompleted, setRoundCompleted] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [saveError, setSaveError] = useState({})
  const auth = useAuth()
  const navigate = useNavigate()

  useEffect(async () => {
    if (!auth?.user) {
      navigate('/login')
    }

    info = await getGeneralInfo()
    const resultCurrentVotes = await getCurrentVotes(auth.user)
    const currentVotesPath = info.current_round + '_votes'
    if (!resultCurrentVotes?.completed_votes || !resultCurrentVotes?.completed_votes.includes(info.current_round)) {
      votes = resultCurrentVotes?.[currentVotesPath] || {}
      feedback = resultCurrentVotes?.feedback || {}
      const entr = await getEntrants(auth.user, info.current_round)
      const categories = getCategories(entr)
      currentVoted = calculateCurrentVoted(categories, entr)
      const quests = await getQuestions(info.current_round)
      setQuests(quests)
      setEntrants(entr)
      setCategories(categories)
      // workAround(entr)

      if (info.current_round !== 'round_3') {
        if (Object.keys(votes).length === entr.length) {
          let count = 0
          for (const id of Object.keys(votes)) {
            count += votes[id].length
          }
          if (count === entr.length * quests?.criteria.length) {
            setUnlockContinue(true)
          } else {
            setUnlockContinue(false)
          }
        }
      }
    } else {
      setRoundCompleted(true)
    }
  }, [])

  const onRankChange = (newRank) => {
    rank = newRank
    setUnlockContinue(!containsNull(rank))
  }
  const handleSaveButton = async () => {
    const votesKeys = _.keys(votes).sort()
    const feedbackKeys = _.keys(feedback).sort()
    if (_.values(votes).every((val) => val.every((val) => val !== 0)) &&
         (info.current_round !== 'round_2' || (_.values(feedback).every((val) => val.every((val) => val !== ''))) &&
         _.isEqual(votesKeys, feedbackKeys))) {
      setSaveError({})
      await saveCurrentVotes(auth.user, votes, info.current_round)
      await saveCurrentFeedback(auth.user, feedback, info.current_round)
      currentVoted = calculateCurrentVoted(categories, entrants)
      setSelectedCategory(null)
    } else {
      let errors = {}
      if (info.current_round !== 'round_2' || !_.isEqual(votesKeys, feedbackKeys)) {
        for (const companyId of _.keys(feedback)) {
          if (votes[companyId]?.includes(0) || feedback[companyId]?.includes('')) {
            errors = {
              ...errors,
              [companyId]: true
            }
          }
          if ((!votes[companyId] && feedback[companyId]) || (votes[companyId] && !feedback[companyId])) {
            errors = {
              ...errors,
              [companyId]: true
            }
          }
        }
      }
      if (info.current_round === 'round_2') {
        for (const companyId of _.keys(votes)) {
          if (votes[companyId]?.includes(0) || feedback[companyId]?.includes('')) {
            errors = {
              ...errors,
              [companyId]: true
            }
          }
          if ((!votes[companyId] && feedback[companyId]) || (votes[companyId] && !feedback[companyId])) {
            errors = {
              ...errors,
              [companyId]: true
            }
          }
        }
      }
      setSaveError(errors)
    }
  }
  const handleCategoryClicked = (category) => {
    filteredEntrants = entrants.filter(function (el) {
      return el.company_info.category === category
    })
    setSelectedCategory(category)
  }

  const calculateCurrentVoted = (categories, entrants) => {
    let result = {}
    for (const category of categories) {
      const companyIds = _.keys(votes)
      const filtered = entrants.filter(function (el) {
        return el.company_info.category === category
      })

      const resultArray = filtered.filter(function (el) {
        return companyIds.includes(el.company_info.id) && votes[el.company_info.id].every((val) => val !== 0)
      })
      result = {
        ...result,
        [category]: resultArray.length + '/' + filtered.length
      }
    }

    return result
  }

  const handleCompleteButton = async () => {
    if (info.current_round === 'round_3') {
      setShowConfirmDialog(true)
    } else {
      await saveCurrentVotes(auth.user, votes, info.current_round)
      await submitFeedback(auth.user, info.current_round, feedback)
      await submitVotes(auth.user, info.current_round, votes, entrants)
      setRoundCompleted(true)
    }
  }
  const handleOnVoteChange = (companyId, index, value) => {
    if (!votes[companyId]) {
      votes[companyId] = [0, 0, 0]
    }
    votes[companyId][index] = value
    if (Object.keys(votes).length === entrants.length) {
      let count = 0
      for (const id of Object.keys(votes)) {
        count += votes[id].length
      }
      if (count === entrants.length * quests.criteria.length) {
        setUnlockContinue(true)
      } else {
        setUnlockContinue(false)
      }
    }
  }

  const handleOnFeedbackChange = (companyId, index, value) => {
    if (!feedback[companyId]) {
      feedback[companyId] = ['', '', '']
    }
    feedback[companyId][index] = value
  }

  const handleConfirm = async () => {
    setShowConfirmDialog(false)
    votes = {
      ...votes,
      [selectedCategory]: true
    }
    await saveCurrentVotes(auth.user, votes, info.current_round)
    await submitVotes(auth.user, info.current_round, rank, selectedCategory)
    if (Object.keys(votes).length === categories.length) {
      setRoundCompleted(true)
      await saveCompleteRound(auth.user, info.current_round)
    } else {
      setSelectedCategory(null)
    }
  }

  const round3 = info.current_round === 'round_3'
  return (
    <MainLayout oppositeColor>
      <PageContainer>
        <Spacer size={6} />
        <View flex centerCenter column style={{ marginLeft: 20, marginRight: 20 }}>
          <img
            alt='logo' src='/assets/airwards_logo.png'
            style={{ width: 300, height: 100, objectFit: 'contain' }}
          />
          {isRoundCompleted
            ? <View centerCenter>
              <ThanksPage round={info.current_round} />
              </View>
            : <View centerCenter flex fullWidth>
              <Box maxWidth='lg' style={{ width: '100%' }}>
                {categories !== null
                  ? <View fullWidth>
                     {info.current_round &&
                       <View>
                          <h3 style={{
                             textAlign: 'center'
                           }}
                           >{'Current Round: ' + info.current_round.substring(6, 7)}
                           </h3>
                        </View>}
                     {
                                 selectedCategory
                                   ? <View>
                                     <CompaniesList
                                       onBackButtonClicked={() => {
                                         filteredEntrants = []
                                         setSelectedCategory(null)
                                       }}
                                       companiesList={filteredEntrants}
                                       questsList={quests?.criteria}
                                       questsDescription={quests?.description}
                                       onVoteChange={handleOnVoteChange}
                                       initialVotes={votes}
                                       initialFeedback={feedback}
                                       onFeedbackChange={handleOnFeedbackChange}
                                       category={selectedCategory}
                                       round={info.current_round}
                                       submitError={saveError}
                                     />
                                     {
                                          info.current_round === 'round_3' &&
                                            <CompaniesRank
                                              companiesList={filteredEntrants}
                                              numRank={filteredEntrants.length > 5 ? 5 : filteredEntrants.length}
                                              onRankChange={onRankChange}
                                            />
                                       }
                                   </View>
                                   : <View flex column fullWidth>
                                     <CategoriesGrid
                                       categoriesList={categories}
                                       onCategoryClicked={handleCategoryClicked}
                                       votes={votes}
                                       round={info.current_round}
                                       voted={currentVoted}
                                     />
                                   </View>
                              }
                     {round3 &&
                       <Typography
                          align='center'
                          variant='body2'
                          component='body1'
                        >If there is a bug, or you change your mind in the middle of voting (i.e. you see blank choice or can't select), please simply refresh your page and continue.
                        </Typography>}
                     {
                                 (selectedCategory || !round3) &&
                                   <Button
                                     disabled={(!round3 && !selectedCategory && !isContinueActive) || (round3 && !isContinueActive)}
                                     onClick={round3 || (!selectedCategory && !round3) ? handleCompleteButton : handleSaveButton}
                                     type='submit'
                                     variant='contained'
                                     fullWidth
                                     sx={{ mt: 1, mb: 2 }}
                                   >
                                     {round3 || (!selectedCategory && !round3) ? 'Submit Votes' : 'Save'}
                                   </Button>
                              }
                     <Dialog
                       disableRestoreFocus
                       disableScrollLock
                       open={showConfirmDialog}
                       onClose={() => setShowConfirmDialog(false)}
                     >
                       <DialogTitle id='alert-dialog-title'>
                          Are you sure you want to submit?
                            </DialogTitle>
                       <DialogContent>
                          <DialogContentText id='alert-dialog-description'>
                             You won't be able to change your votes once you've done this.
                              </DialogContentText>
                        </DialogContent>
                       <DialogActions>
                          <Button onClick={() => setShowConfirmDialog(false)} autoFocus>
                             Close
                              </Button>
                          <Button onClick={handleConfirm}>Confirm</Button>
                        </DialogActions>
                     </Dialog>
                     {/* <CompaniesRank
                                  companiesList={entrants}
                              /> */}
                   </View>
                  : <View centerCenter flex>
                     <CircularProgress />
                   </View>}
              </Box>
              </View>}
          <Spacer size={10} />
        </View>
      </PageContainer>
    </MainLayout>
  )
}
