import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {getPublicEntrants} from '../../controllers/mainController';
import PageContainer from '../../layouts/components/Body/PageContainer';
import View from '../../components/theme/View';
import CompaniesList from '../../components/Company/CompaniesList';
import CompaniesRank from '../../components/Company/CompaniesRank';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {getCategories} from '../dashboard/utility';
import _ from 'lodash';
import Spacer from '../../components/Spacer/Spacer';
import {validateEmail} from './utility';
import {
  checkIfEmailIsUsed,
  isEmailUsed,
  submitPublicVotes,
} from '../../controllers/publicVotingController';
import YourVotes from '../../components/YourVotes';
import Grid from '../../components/theme/Grid';
import Typography from '../../components/theme/Typography';
import {useViewport} from '../../utils/dimensions/Viewport';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import validator from 'validator';
import {ReCAPTCHA} from 'react-google-recaptcha';

let current_votes = {};
let filteredEntrants = [];
const votedEntrants = [];
let rank = {};
const url =
  'https://online.us10.list-manage.com/subscribe/post?u=7c648940c53d99a0a1aac7acf&id=18b76d32b4';

const PublicVoting = () => {
  const {isXS} = useViewport();
  const [entrants, setEntrants] = useState(null);
  const [categories, setCategories] = useState(null);
  const [isVoteCompleted, setIsVoteCompleted] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [group, setGroup] = useState(null);
  const [isContinueActive, setUnlockContinue] = useState(false);
  const [isEmailStep, setEmailStep] = useState(false);
  const [privacyTick, setPrivacyTick] = useState(false);
  const [marketingTick, setMarketingTick] = useState(false);
  const [submitTick, setSubmitTick] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [tickError, setTickError] = useState(false);
  const recaptchaRef = useRef(null);

  useEffect(async () => {
    const publicVotingSubmitted =
      localStorage.getItem('publicVotingSubmitted') === 'true';
    if (publicVotingSubmitted) {
      setIsVoteCompleted(true);
      window.open(
        'https://www.airwards.online/thanks-for-voting-ppl-chc',
        '_self'
      );
    } else {
      const votesString = localStorage.getItem('publicVoting');
      current_votes = JSON.parse(votesString) || {};

      const entr = await getPublicEntrants();
      if (current_votes !== {}) {
        for (const category of Object.keys(current_votes)) {
          if (current_votes[category] !== 'skip') {
            const votedEntrant = _.find(entr, function (o) {
              return o.company_info.id === current_votes[category];
            });
            votedEntrants.push(votedEntrant);
          }
        }
      }
      console.log(votedEntrants);
      const categories = getCategories(entr);
      setEntrants(entr);
      const nextCategory = _.find(categories, function (o) {
        return !_.keys(current_votes).includes(o);
      });
      if (!nextCategory) {
        setEmailStep(true);
      } else {
        filteredEntrants = entr.filter(function (el) {
          return el.company_info.category === nextCategory;
        });
        setSelectedCategory(nextCategory);
        setGroup(filteredEntrants[0].company_info.group);
        setCategories(categories);
      }
    }
  }, []);

  const onRankChange = (newRank) => {
    rank = newRank;
    if (rank[1]) {
      setUnlockContinue(true);
    } else {
      setUnlockContinue(false);
    }
  };

  const handleCompleteButton = (skip) => {
    current_votes = {
      ...current_votes,
      [selectedCategory]: skip ? 'skip' : rank[1],
    };
    if (!skip) {
      const votedEntrant = _.find(entrants, function (o) {
        return o.company_info.id === rank[1];
      });
      votedEntrants.push(votedEntrant);
    }
    console.log('Voted Entrants:', votedEntrants);

    localStorage.setItem('publicVoting', JSON.stringify(current_votes));
    const nextCategory = _.find(categories, function (o) {
      return !_.keys(current_votes).includes(o);
    });
    if (!nextCategory) {
      setEmailStep(true);
    } else {
      filteredEntrants = entrants.filter(function (el) {
        return el.company_info.category === nextCategory;
      });
      setGroup(filteredEntrants[0].company_info.group);
      setSelectedCategory(nextCategory);
      setUnlockContinue(false);
    }
  };

  return (
    <PageContainer>
      <View centerCenter flex fullWidth>
        <Box maxWidth="lg" style={{width: '100%'}}>
          <Spacer size={3} />
          {isVoteCompleted ? (
            <View centerCenter />
          ) : isEmailStep ? (
            <View>
              <Grid container justifyContent="center">
                <Grid item xs={12} lg={6}>
                  <YourVotes votes={votedEntrants} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <View flex justifyContent="center" column>
                    <Typography align="center" variant="h5" component="h2">
                      About you
                    </Typography>
                    <Spacer size={2} />
                    <Grid
                      item
                      container
                      direction={isXS ? 'column' : 'row'}
                      justifyContent="center"
                      spacing={1}
                    >
                      <Grid item xs={10} lg={5}>
                        <TextField
                          error={nameError}
                          helperText={nameError}
                          fullWidth
                          label="Full Name"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={10} lg={5}>
                        <TextField
                          error={emailError}
                          helperText={emailError}
                          fullWidth
                          label="Email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item style={{margin: 20}}>
                      <Box
                        component="form"
                        sx={{
                          '& > :not(style)': {m: 1},
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={privacyTick}
                              onChange={() => {
                                setPrivacyTick(!privacyTick);
                              }}
                              size="small"
                            />
                          }
                          label="(Required) Sign me up to Airwards to be first in line for news and more by email"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => {
                                setMarketingTick(!marketingTick);
                              }}
                              checked={marketingTick}
                              size="small"
                            />
                          }
                          label="I’d like to receive updates, offers, and more from Airwards partners."
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => {
                                setSubmitTick(!submitTick);
                              }}
                              checked={submitTick}
                              size="small"
                            />
                          }
                          label="(Required) By submitting, you agree to Airwards privacy policies and T&Cs."
                        />
                        {tickError && (
                          <Typography
                            color="error"
                            align="center"
                            variant="body2"
                          >
                            Check the required checkboxes
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <MailchimpSubscribe
                      url={url}
                      render={({subscribe, status, message}) => (
                        <Grid container item justifyContent="center">
                          <Button
                            onClick={async () => {
                              setTickError(false);
                              setEmailError(null);
                              setNameError(null);
                              if (name.trim() !== '') {
                                if (!privacyTick || !submitTick) {
                                  setTickError(true);
                                } else {
                                  if (validator.isEmail(email.trim())) {
                                    if (!(await isEmailUsed(email.trim()))) {
                                      const firstName = name.split(' ', 1)[0];
                                      const lastName = name.replace(
                                        firstName + ' ',
                                        ''
                                      );
                                      const result = {
                                        EMAIL: email.trim(),
                                        FNAME: firstName,
                                        LNAME: lastName,
                                        tags: '11157685',
                                        PRTNRCNTNT: marketingTick
                                          ? '100% Yes, I am intrigued'
                                          : 'Nah, not for me',
                                      };
                                      subscribe(result);
                                      await submitPublicVotes(
                                        email,
                                        current_votes
                                      );
                                      console.log(status, message);
                                      // setIsVoteCompleted(true)
                                      localStorage.setItem(
                                        'publicVotingSubmitted',
                                        'true'
                                      );
                                      window.open(
                                        'https://www.airwards.online/thanks-for-voting-ppl-chc',
                                        '_self'
                                      );
                                    } else {
                                      console.log('is used');
                                      setEmailError('Email already used');
                                    }
                                  } else {
                                    setEmailError('Invalid email');
                                  }
                                }
                              } else {
                                setNameError('The name cannot be empty');
                              }
                            }}
                            type="submit"
                            variant="contained"
                            sx={{mt: 1, mb: 2}}
                          >
                            Submit
                          </Button>
                        </Grid>
                      )}
                    />
                  </View>
                </Grid>
              </Grid>
            </View>
          ) : (
            <View centerCenter>
              {categories !== null ? (
                <View style={{margin: 20}}>
                  <CompaniesList
                    companiesList={filteredEntrants}
                    category={selectedCategory}
                    isPublic
                    onVoteClick={() => handleCompleteButton()}
                    onRankChange={onRankChange}
                    numRank={1}
                  />
                  {/* <CompaniesRank
                    companiesList={filteredEntrants}
                    numRank={1}
                    onRankChange={onRankChange}
                    isPublic
                    handleVote={() => handleCompleteButton()}
                  /> */}
                  <View flex column style={{justifyContent: 'flex-start'}}>
                    <Tooltip title="Skip to next category" placement="right">
                      <IconButton
                        sx={{
                          width: '100px',
                          marginTop: '50px',
                          transform: 'scale(1)',
                          transition: 'transform 0.3s ease-in-out',

                          '&:hover': {
                            backgroundColor: 'transparent',
                            transform: 'scale(1.5)',
                            transition: 'transform 0.3s ease-in-out',
                          },
                        }}
                      >
                        <KeyboardDoubleArrowRightIcon
                          onClick={() => handleCompleteButton('true')}
                          color="primary"
                          size="large"
                          sx={{fontSize: '100px'}}
                        />
                      </IconButton>
                    </Tooltip>
                    {/* <Button
                      onClick={() => handleCompleteButton('true')}
                      type="submit"
                      variant="contained"
                      sx={{mt: 1, mb: 2}}
                    >
                      Skip
                    </Button> */}
                    {/* <Spacer horizontal />
                    <Button
                      disabled={!isContinueActive}
                      onClick={() => handleCompleteButton()}
                      type="submit"
                      variant="contained"
                      sx={{mt: 1, mb: 2}}
                    >
                      Vote
                    </Button> */}
                  </View>
                </View>
              ) : (
                <CircularProgress />
              )}
            </View>
          )}
        </Box>
      </View>
    </PageContainer>
  );
};

export default PublicVoting;
