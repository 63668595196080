const layout = {
  flex1: {
    flex: 1
  },
  flex: {
    display: 'flex'
  },
  flexGrow: {
    flexGrow: 1
  },
  flexShrink: {
    flexShrink: 1
  },
  horizontal: {
    // display: 'flex',
    flexDirection: 'row'
  },
  wrap: {
    flexWrap: 'wrap'
  },
  noWrap: {
    flexWrap: 'nowrap'
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  overflowVisible: {
    overflow: 'visible'
  },
  row: {
    // display: 'flex',
    flexDirection: 'row'
  },
  column: {
    // display: 'flex',
    flexDirection: 'column'
  },
  centerCenter: {
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  justifyEnd: {
    // display: 'flex',
    justifyContent: 'flex-end'
  },
  alignEnd: {
    // display: 'flex',
    alignItems: 'flex-end'
  },
  justifyBetween: {
    // display: 'flex',
    justifyContent: 'space-between'
  },
  block: {
    display: 'block'
  }
}

export default layout
