const typography = {

  // FONT SIZE
  defaultFontSize: 14,
  h1FontSize: '5.5rem',
  h1FontWeight: 900,
  h2FontSize: '3.5rem',
  h2FontWeight: 900,
  h3FontSize: '2.75rem',
  h3FontWeight: 900,
  h4FontSize: '2.124rem',
  h4FontWeight: 600,
  h5FontSize: '1.5rem',
  h5FontWeight: 600,
  h6FontSize: '1.25rem',
  h6FontWeight: 600,
  subtitle1FontSize: '1rem',
  subtitle2FontSize: '0.875rem',
  body1FontSize: '1rem',
  body2FontSize: '0.875rem',
  buttonFontSize: '0.875rem',
  sidebarFontSize: '0.875rem',

  // FONT WEIGHT
  tiny: '100',
  regular: '300',
  semiBold: '600',
  bold: '900'
}
export default typography
