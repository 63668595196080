import R from '../variables'
import { darken, lighten } from '@mui/material'

const elements = {
  scrollbar: {
    overflowY: 'scroll',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: R.dimensions.sidebarDimension,
      height: R.dimensions.sidebarDimension
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: R.palette.themeDark ? lighten(R.palette.backgroundColorDark, 0.4) : darken(R.palette.backgroundColorLight, 0.4),
      outline: '0px solid slategrey',
      borderRadius: 200
    }
  },
  categoriesListItem: {

  },
  backgroundGradient: {
    background: 'linear-gradient(to bottom right, ' + R.palette.backgroundColored1 + ', ' + R.palette.backgroundColored2 + ')'
  },
  backgroundFlat: {
    backgroundColor: R.palette.backgroundColored1
  }

}

export default elements
