import View from '../theme/View'
import React from 'react'
import { makeStyles } from '@mui/styles'

const ToolbarSpacer = () => {
  const classes = useStyles()
  return (
    <View className={classes.toolbarSpace} />
  )
}

const useStyles = makeStyles((theme) => ({
  toolbarSpace: {
    height: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(8)
    }
  }
}))

export default ToolbarSpacer
