import { useEffect, useState } from 'react'
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  TextField
} from '@mui/material'
import View from '../components/theme/View'
import PageContainer from '../layouts/components/Body/PageContainer'
import MainLayout from '../layouts/MainLayout/MainLayout'
import Spacer from '../components/Spacer/Spacer'
import {
  getGeneralInfo,
  updateCurrentInfo
} from '../controllers/mainController'

export const AdminPage = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const [currentRound, setCurrentRound] = useState('round_1')
  const [roundOnePassMark, setRoundOnePassMark] = useState(70)

  const handleCurrentRoundChange = e => {
    setCurrentRound(e.target.value)
  }

  const handleRoundOnePassChange = e => {
    setRoundOnePassMark(e.target.value)
  }

  const handleRoundSave = async () => {
    await updateCurrentInfo({ current_round: currentRound })
  }

  const handleRoundOneInfoUpdate = async () => {
    await updateCurrentInfo({ round_one_pass: roundOnePassMark })
  }

  useEffect(() => {
    const loadData = async () => {
      const info = await getGeneralInfo()
      setCurrentRound(info.current_round)
      setRoundOnePassMark(info.round_one_pass)
      setPageLoading(false)
    }

    loadData()
  }, [])

  return (
    <MainLayout oppositeColor>
      {!pageLoading && (
        <PageContainer>
          <Spacer size={6} />
          <View padding={6} flex justify='space-around'>
            <FormControl>
              <InputLabel id='current-round-label'>Current Round</InputLabel>
              <Select
                labelId='current-round-label'
                id='current-round-select'
                value={currentRound}
                label='Current Round'
                onChange={handleCurrentRoundChange}
              >
                <MenuItem value='round_1'>Round One</MenuItem>
                <MenuItem value='round_2'>Round Two</MenuItem>
                <MenuItem value='round_3'>Round Three</MenuItem>
              </Select>
              <Button onClick={handleRoundSave}>Save</Button>
            </FormControl>
            <FormControl>
              <TextField
                id='round-one-pass-mark'
                type='number'
                label='Round One Pass Mark'
                value={roundOnePassMark}
                onChange={handleRoundOnePassChange}
              />
              <Button onClick={handleRoundOneInfoUpdate}>Save</Button>
            </FormControl>
          </View>
        </PageContainer>
      )}
    </MainLayout>
  )
}
