const menuStructure = [
  {
    title: 'Home',
    href: '/'
  },
  {
    title: 'Contact Us',
    href: 'mailto:hello@airwards.online?subject=Judges Scoring | Question'
  },
  {
    title: 'Logout',
    href: '/logout'
  }
]
export default menuStructure
