import React from 'react'
import View from '../../../components/theme/View'
import { Collapse, Divider, ListItem, ListItemText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import menuStructure from '../../../menuStructure'
import List from '../../../components/theme/List'
// import {LinkRoute} from "../../../Router";
import Typography from '../../../components/theme/Typography'
import { events } from '../../../utils/eventsTracking/events'
import Link from '../../../components/theme/Link'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { trackEvent } from '../../../utils/eventsTracking/gtag'

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  element: {
    fontWeight: 600,
    '& .MuiTypography-root:hover': {
      cursor: 'pointer'
    }
  },
  toolbar: {
    minHeight: theme.mixins.toolbar.minHeight,
    paddingLeft: theme.spacing(2)
  }
}))

const MenuSidebarElements = ({ handleDrawerToggle }) => {
  const classes = useStyles()
  const [menuElementsClicked, setMenuElementsClicked] = React.useState({})

  const handleMenuClick = (element) => {
    const menuElementsClickedUpdate = { ...menuElementsClicked }
    menuElementsClickedUpdate[element.title] = !menuElementsClickedUpdate[element.title]
    setMenuElementsClicked(menuElementsClickedUpdate)
    if (!element.subMenu) {
      handleDrawerToggle(element)
    }
    if (!element.subMenu && element.href && element.href !== '') {
      setTimeout(() => {
        trackEvent(events.button_clicked_menu, { navigate: element.title })
        // history.push(element.href);
        // router.push(`${element.href}`)
      }, 100)
    }
  }

  return (
    <View>
      <View className={classes.toolbar} alignItems='center'>
        <Typography variant='h6' weight={600}>Menu</Typography>
      </View>
      <Divider />
      <List>
        {
                    menuStructure.map(function (element) {
                      return (
                        <Link key={element.title} href={element.href ? element.href : null}>
                          <ListItem
                            button
                            key={element.title}
                            onClick={() => {
                              handleMenuClick(element)
                            }}
                          >
                            <ListItemText primary={element.title} />
                            {element?.subMenu?.length > 0
                              ? menuElementsClicked[element.title] ? <ExpandLess /> : <ExpandMore />
                              : null}
                          </ListItem>
                          {
                                    element?.subMenu?.length > 0
                                      ? <View>
                                        <Collapse
                                          in={menuElementsClicked[element.title]}
                                          timeout='auto'
                                          mountOnEnter={false}
                                          unmountOnExit={false}
                                        ><List>
                                          {
                                                    element.subMenu.map(function (subElement) {
                                                      return (
                                                        <Link key={subElement.title} href={subElement.href}>
                                                          <ListItem
                                                            button key={subElement.title}
                                                            className={classes.nested}
                                                            onClick={() => {
                                                              handleMenuClick(subElement)
                                                            }}
                                                          >
                                                            <ListItemText
                                                              primary={subElement.title}
                                                              className={classes.element}
                                                            />
                                                          </ListItem>
                                                        </Link>
                                                      )
                                                    })
                                                }
                                        </List>
                                        </Collapse>
                                      </View>
                                      : null
                                }
                        </Link>
                      )
                    })
                }
      </List>
    </View>
  )
}

export default MenuSidebarElements
