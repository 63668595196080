import View from '../../../components/theme/View'
import { makeStyles } from '@mui/styles'
import React from 'react'
import Spacer from '../../../components/Spacer/Spacer'
import ToolbarSpacer from '../../../components/Spacer/ToolbarSpacer'
import Grid from '../../../components/theme/Grid'
import ImageCustom from '../../../components/ImageCustom/ImageCustom'
import Typography from '../../../components/theme/Typography'
import S from '../../../res/styles'
import { useViewport } from '../../../utils/dimensions/Viewport'

const HeaderPage = ({ children, title, img, subTitle, subTitle2 }) => {
  const classes = useStyles()
  const { isXS } = useViewport()

  return (
    <View className={classes.root}>
      <View className={[classes.mainWrapper]} centerCenter column>
        <ToolbarSpacer />
        <Grid container spacing={2} justify='center'>
          <Grid
            container
            item
            justify='center'
            alignItems='center'
            direction='column'
            xs={12} sm={6}
          >
            <View className={[classes.textWrapper]}>
              <View>
                <Typography
                  variant={isXS ? 'h3' : 'h2'} component='h1' oppositeColor
                  align={(subTitle || subTitle2) ? 'left' : 'center'}
                >{title}
                </Typography>
                {subTitle &&
                  <View>
                      <Spacer size={3} />
                      <Typography
                        align='left'
                        variant='h6'
                        weight={300}
                        oppositeColor
                      >
                        {subTitle}
                      </Typography>
                    </View>}
                {subTitle2 &&
                  <View>
                      <Spacer />
                      <Typography
                        align='left'
                        variant='h6'
                        weight={300}
                        oppositeColor
                      >
                        {subTitle2}
                      </Typography>
                    </View>}
              </View>
            </View>
          </Grid>
          {img &&
            <Grid container item xs={12} sm={4} justify='center'>
              <ImageCustom
                src={img.src}
                alt={img.alt}
                exactHeight={img.height}
                exactWidth={img.width}
                desktopHeight={550}
              />
            </Grid>}
        </Grid>
      </View>
      <View centerCenter row>
        <View column centerCenter>
          <Spacer size={2} />
          {children}
        </View>
      </View>
      <Spacer size={3} />
    </View>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  mainWrapper: {
    minHeight: 250,
    ...S.elements.backgroundGradient,
    // backgroundColor: '#1b1d71',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  },
  body: {}
}))

export default HeaderPage
