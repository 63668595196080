import React from 'react'
import classNames from 'classnames'
import { Container as MuiContainer } from '@mui/material'
import { makeStyles } from '@mui/styles'

const Container = ({ className, children, maxWidth, ...rest }) => {
  const classes = useStyles()

  const rootClassName = classNames(
    {
      [classes.root]: true
    },
    className
  )

  return (
    <MuiContainer
      maxWidth={maxWidth}
      {...rest}
      className={rootClassName}
    >
      {children}
    </MuiContainer>
  )
}

const useStyles = makeStyles(theme => ({
  root: {}

}))

export default Container
