import React, { useEffect, useState } from 'react'
import View from '../theme/View'
import { makeStyles } from '@mui/styles'
import Typography from '../theme/Typography'
import Spacer from '../Spacer/Spacer'
import { useViewport } from '../../utils/dimensions/Viewport'
import RankBox from '../RankBox/RankBox'

let rank = {}

const CompaniesRank = ({ companiesList, numRank, onRankChange, isPublic }) => {
  const classes = useStyles()
  const { isXS } = useViewport()
  const [companyIdsSelected, setCompanyIdsSelected] = useState([])
  useEffect(() => {
    for (let i = 1; i < numRank + 1; i++) {
      rank = {
        ...rank,
        [i]: null
      }
    }
    setCompanyIdsSelected([])
  }, [companiesList])

  const onOptionChange = (event, index) => {
    const value = event.target.value
    const cloneIds = [...companyIdsSelected]

    const prevValue = rank[index]
    if (!value) {
      const idRemoved = rank[index]
      cloneIds.splice(cloneIds.indexOf(idRemoved), 1)
      rank[index] = null
    } else if (prevValue) {
      cloneIds.splice(cloneIds.indexOf(prevValue), 1)
      rank[index] = null
      cloneIds.push(value.company_info.id)
      rank[index] = value.company_info.id
    } else {
      cloneIds.push(value.company_info.id)
      rank[index] = value.company_info.id
    }
    setCompanyIdsSelected(cloneIds)

    onRankChange(rank)
  }

  return (
    <View className={classes.root} centerCenter flex>
      <View className={classes.wrapper}>
        <Spacer size={3} />
        <Typography
          align='center'
          variant='h4'
        >{isPublic ? 'Your choice' : 'Rank'}
        </Typography>
        <Spacer size={2} />
        <RankBox
          companiesList={companiesList}
          companyIdsSelected={companyIdsSelected}
          numRank={numRank}
          onOptionChange={onOptionChange}
          isPublic={isPublic}
        />
        <Spacer size={1} />
      </View>
    </View>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  wrapper: {
    width: '100%',
    maxWidth: 700
  }
}))

export default CompaniesRank
