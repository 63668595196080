import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useTheme } from '@mui/material'

const viewportContext = React.createContext({})

export const ViewportProvider = ({ children }) => {
  const theme = useTheme()
  const [width, setWidth] = React.useState(1900)
  const [height, setHeight] = React.useState(1200)
  const [isXS, setIsXS] = React.useState(false)
  const [isSM, setIsSM] = React.useState(false)
  const [isMD, setIsMD] = React.useState(false)
  const [isLG, setIsLG] = React.useState(false)
  const [isXL, setIsXL] = React.useState(true)

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
    setIsXS((isMobileOnly || window.innerWidth <= theme.breakpoints.values.sm))
    setIsSM((isMobileOnly || window.innerWidth <= theme.breakpoints.values.md))
    setIsMD((isMobileOnly || window.innerWidth <= theme.breakpoints.values.lg))
    setIsLG((isMobileOnly || window.innerWidth <= theme.breakpoints.values.xl))
    setIsXL((isMobileOnly || window.innerWidth > theme.breakpoints.values.xl))
  }

  React.useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return (
    <viewportContext.Provider value={{ width, height, isXS, isSM, isMD, isLG, isXL }}>
      {children}
    </viewportContext.Provider>
  )
}

export const useViewport = () => {
  const { width, height, isXS, isSM, isMD, isLG, isXL } = React.useContext(viewportContext)
  return { width, height, isXS, isSM, isMD, isLG, isXL }
}
