import { useTheme } from '@mui/material'
import color from 'color'

export const IsColorInPalette = (color) => {
  const theme = useTheme()
  return !!(color && color !== 'gray' && theme.palette[color])
}

export const darkenColor = (colorToHandle, ratio) => {
  return color(colorToHandle)
    .darken(ratio)
    .hex()
}

export const lightenColor = (colorToHandle, ratio) => {
  return color(colorToHandle)
    .lighten(ratio)
    .hex()
}

export const desaturateColor = (colorToHandle, ratio) => {
  return color(colorToHandle)
    .desaturate(ratio)
    .hex()
}

export const grayscaleColor = (colorToHandle) => {
  return color(colorToHandle)
    .grayscale()
    .hex()
}
