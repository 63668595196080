import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch
} from 'firebase/firestore'
import { firestoreDb } from '../config/firebase'

export const getGeneralInfo = async () => {
  const docRef = doc(firestoreDb, 'info', 'current')
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  }
  return null
}

export const getEntrants = async (judgeId, round) => {
  const entrants = []
  const roundJudges = round + '_judges'
  const q = query(collection(firestoreDb, 'entrants'), where(roundJudges, 'array-contains', judgeId))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    entrants.push(doc.data())
  })
  return entrants
}

export const getQuestions = async (round) => {
  const docRef = doc(firestoreDb, 'questions', round)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  }
  return null
}

export const getCurrentVotes = async (id) => {
  const docRef = doc(firestoreDb, 'judges', id)
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  }
  return null
}

export const saveCurrentVotes = async (id, votes, round) => {
  const docRef = doc(firestoreDb, 'judges', id)
  const currentVotesPath = round + '_votes'
  await updateDoc(docRef, {
    [currentVotesPath]: votes
  })
}
export const saveCurrentFeedback = async (id, feedback, round) => {
  if (round === 'round_2') {
    const docRef = doc(firestoreDb, 'judges', id)
    await updateDoc(docRef, {
      feedback
    })
  }
}

export const submitVotes = async (id, round, votes, category) => {
  const batch = writeBatch(firestoreDb)
  if (round === 'round_3') {
    for (const position of Object.keys(votes)) {
      const docRef = doc(firestoreDb, 'votes', round, 'entrants', votes[position])
      batch.set(docRef, {
        votes: {
          [id]: position
        },
        category
      }, { merge: true })
    }
  } else {
    for (const companyId of Object.keys(votes)) {
      const docRef = doc(firestoreDb, 'votes', round, 'entrants', companyId)
      batch.set(docRef, {
        votes: {
          [id]: votes[companyId]
        }
        // category: category[companyId]?.company_info?.category
      }, { merge: true })
    }
    const judgeRef = doc(firestoreDb, 'judges', id)
    batch.set(judgeRef, { completed_votes: arrayUnion(round) }, { merge: true })
  }

  return batch.commit()
}
export const workAround = async (entries) => {
  console.log(entries)
  /* const batch = writeBatch(firestoreDb);

   for (let companyId of Object.keys(entries)) {
      const docRef = doc(firestoreDb, "votes", 'round_2', 'entrants', entries[companyId]?.company_info?.id);
   batch.set(docRef, {
      category: entries[companyId]?.company_info?.category
   }, {merge: true});
   }
   return batch.commit(); */
}
export const submitFeedback = async (id, round, feedback) => {
  const batch = writeBatch(firestoreDb)
  if (round === 'round_2') {
    for (const companyId of Object.keys(feedback)) {
      const docRef = doc(firestoreDb, 'votes', round, 'entrants', companyId)
      batch.set(docRef, {
        feedback: {
          [id]: feedback[companyId]
        }
      }, { merge: true })
    }
    return batch.commit()
  }
}

export const saveCompleteRound = (id, round) => {
  const judgeRef = doc(firestoreDb, 'judges', id)
  return setDoc(judgeRef, { completed_votes: [round] }, { merge: true })
}

export const getPublicEntrants = async () => {
  const entrants = []
  const q = query(collection(firestoreDb, 'public_entrants'))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    entrants.push(doc.data())
  })
  return entrants
}

export const updateCurrentInfo = async (update) => {
  try {
    const docRef = doc(firestoreDb, 'info', 'current')
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
      await updateDoc(docRef, update)
    }
    return null
  } catch (e) {
    console.error(e)
    return null
  }
}
