import React from 'react'
import PropTypes from 'prop-types'
import View from '../../components/theme/View'
import Body from '../components/Body/Body'
import Sidebar from '../components/Sidebar/Sidebar'
import { useViewport } from '../../utils/dimensions/Viewport'
import { CssBaseline } from '@mui/material'
import Topbar from '../components/Topbar/Topbar'
// import Sidebar from "../components/Sidebar/Sidebar";

const MainLayout = ({ children, oppositeColor }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const { isXS } = useViewport()

  return (
    <View flex>
      <CssBaseline />
      <Topbar handleDrawerToggle={handleDrawerToggle} mount={!isXS} oppositeColor={oppositeColor} />
      <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} mount={isXS} />
      <Body noPadding>
        {children}
      </Body>
    </View>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node
}

export default MainLayout
