import React from 'react'
import { darken } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import View from '../../../components/theme/View'
// import Footer from "../Footer/Footer";
import { useViewport } from '../../../utils/dimensions/Viewport'
import Footer from '../Footer/Footer'

const Body = ({ children, noPadding }) => {
  const classes = useStyles({ noPadding })
  const { width } = useViewport()

  const mainClassName = classNames(
    {
      [classes.content]: true
    }
  )

  return (
    <View flex1 column>
      <View className={classes.toolbar} />
      <main className={mainClassName} style={{ maxWidth: '100vw' }}>
        {children}
      </main>
      {/* <Footer/> */}
    </View>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {},
  // necessary for content to be below app bar
  // toolbar: theme.mixins.toolbar,
  content: props => ({
    flexGrow: 1,
    padding: props.noPadding ? 0 : theme.spacing(3),
    overflow: 'hidden',
    minHeight: '100vh'
  }),
  gradientBackground: {
    background: `linear-gradient(${darken(theme.palette.primary.main, 0.7)} 0%, ${darken(theme.palette.primary.main, 0.9)} 350px);`
  }
}))

Body.propTypes = {
  noPadding: PropTypes.bool
}
export default Body
