import React from 'react'
import SignIn from './pages/signin/SigninPage'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { AuthProvider, useAuth } from './contexts/authContext'
import Import from './pages/import/ImportPage'
import DashboardPage from './pages/dashboard/DashboardPage'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/styles'
import PublicVoting from './pages/publicVoting/publicVotingPage'
import { AdminPage } from './pages/AdminPage'

const theme = createTheme({
  typography: {
    fontFamily: 'Rubik'
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: 'Rubik'
      }
    }
  }
})

export default function App () {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Routes>
            <Route
              path='/'
              element={
                <RequireAuth>
                  <DashboardPage />
                </RequireAuth>
              }
            />
            <Route path='/login' element={<SignIn />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/maketheimport' element={<Import />} />
            <Route path='/voting' element={<PublicVoting />} />
            <Route
              path='/admin'
              element={
                <RequireAdmin>
                  <AdminPage />
                </RequireAdmin>
              }
            />
          </Routes>
        </AuthProvider>
      </ThemeProvider>
    </div>
  )

  function RequireAuth ({ children }) {
    const auth = useAuth()
    const location = useLocation()

    // TODO re-add
    if (!auth.user) {
      return <Navigate to='/login' state={{ from: location }} />
    }
    // return <Navigate to="/" state={{from: location}}/>;

    return children
  }

  function Logout () {
    const auth = useAuth()
    const location = useLocation()
    auth.signout()
    return <Navigate to='/login' state={{ from: location }} />
  }
}

function RequireAdmin ({ children }) {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.user && auth.user !== 'rj_nichols@hotmail.com') {
    return <Navigate to='/login' state={{ from: location }} />
  }

  return children
}